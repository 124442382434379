@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');

*,
*::after,
*::before {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  font-family: 'Russo One', sans-serif;
  box-sizing: border-box;
  line-height: 1.5;
  letter-spacing: 2px;
  height: 100%;
}

.App {
  text-align: center;
  box-sizing: border-box;
  height: 100%;
  min-height: 100vh;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  padding-top: 15px;
  background-color: #EBEDEF;
  border-bottom: 1px solid #666;
  box-shadow: 0px 5px 10px #333;
}

h1 {
  color: blue;
}

.currentDay {
  color: blue;
  font-size: 1.3rem;
  /* padding: 100; */
  display: inline-block;
}

.searchContainer {
  display: inline-block;
  padding: 30px;
}

.btn, .btn:link, .btn:visited {
  cursor: pointer;
  display: inline-block;
  padding: 1rem 3rem;
  margin-top: 1.1rem;
  border: none;
  border-radius: .8rem;
  position: relative;
  text-decoration: none;
}

button {
  font-family: 'Russo One', sans-serif;
  font-size: 1.2rem;
}

.btn:hover {
  transform: scale(1.1);
  border: none;
}

.btn-blue {
  background-color: darkblue;
  color: white;
}

.btn-green {
  background-color: darkgreen;
  color: white;
}

.btn-dark {
  background-color: #333;
  color: white;
}

.btn-nav {
  padding: .3rem .5rem !important;
}

.btn-update {
  font-size: 1rem;
}

.form-input {
  outline: 0;
  border: 0;
  border-bottom: 3px solid green;
  width: 100%;
  color: green;
  padding: 1rem 2rem .5rem 2rem;
  font-family: inherit;
  font-size: 1.2rem;
  display: block;
  margin-bottom: .8rem;
}

.form-input:focus {
  border-bottom: 3px solid blue;
}

.form-input::-webkit-input-placeholder {
  color: #999;
}

label {
  cursor: pointer;
}

.main-container {
  margin-bottom: 100;
  display: flex;
  flex-direction: row;
  flex: 0 1 auto;
  justify-content: space-evenly;
  align-content: stretch;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 60px;
}

.left-side {
  width: 45%;
  padding-top: 15px;
  position: fixed;
  top: 100px;
  left: 0;
}

.schedule-nav {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
}

.schedule-items {
  flex: 1;
  /* justify-content: center;
  align-items: center; */
  text-align: center;
  padding-left: 45%;
}

.schedule-item {
  padding: 10px;
  color: white;
  margin: 10px auto 15px;
  border-radius: 8px;
  box-shadow: 0 5px 5px #666;

  animation-name: fadeIn;
  animation-duration: 1s;
}

.spinner {
  height: 500px;
  /* padding-right: 100px; */
  padding-top: 100px;
}

.no-results {
  color: lightcoral;
  padding: 50px 0px;
}

.search-results {
  color: blue;
}

.toggle-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.toggle-close {
  display: none;
  z-index: 20;
}

.toggle-close, .toggle-open {
  margin-bottom: 10px;
  cursor: pointer;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 10px;
  background-color: white;
  border-top: 1px solid #666;
  box-shadow: 0px -5px 10px #333;
  height: 50px;
  /* padding: 30px; */
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .main-container {
    flex-direction: column;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 70px;
  }

  .left-side {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding-top: 10px;
    position: static;
  }

  .schedule-items {
    padding-left: 0;
  }

  .schedule-item {
  border-radius: 0;
  }

  .header {
    padding-bottom: 10px;
    padding-top: 10px;
    height: 60px;
  }

  h1 {
    font-size: 1.5rem;
  }

  .btn, .btn:link, .btn:visited {
    padding: .7rem 2rem;
  }

  .spinner {
    height: 300px;
    padding-top: 100px;
  }

  .checkboxes {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .checkboxes > div {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0px !important;
  }

  .checkboxes > div:last-child a {
    margin-top: .2rem;
  }

  .footer {
    height: 60px;
    font-size: .8rem;
    padding: 0 10px;
  }

  .btn-update {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 550px) {

  .main-container {
    margin-top: 30px;
  }

  .header {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  
  .left-side {
    position: fixed;
    top: -1000px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: .7s;
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
    z-index: 10;
    background-color: white;
    padding-top: 0;
  }

  h1 {
    font-size: 1.1rem;
  }

  .calendar {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    width: 360px;
  }

  .searchContainer {
    padding-top: 15px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
  }

  .currentDay {
    font-size: 1.1rem;
    line-height: 1.6;
  }

  .btn-nav {
    font-size: .8rem;
  }

  .btn-nav:hover {
    transform: none;
  }

  .btn-update {
    margin-top: 10px !important;
    padding: .5rem 1.5rem !important;
  }

  .close-button {
    margin-top: 2rem !important;
    padding: .5rem 1.5rem !important;

  }

  .schedule-nav {
    align-items: flex-end;
  }

  .checkboxes {
    flex-direction: column;
  }

  .checkboxes > div {
    margin-bottom: 10px !important;
  }
}

@media only screen and (max-height: 600px) {
  .close-button {
    margin-top: .8rem !important;
  }

  .main-container {
    padding-top: 0;
  }

  .left-side {
    padding-top: 0;
  }
  
}

@keyframes fadeIn {
  10% {
      opacity: .1;
  }
  20% {
      opacity: .2;
  }
  30% {
      opacity: .3;
  }
  40% {
      opacity: .4;
  }
  50% {
      opacity: .5;
  }
  60% {
      opacity: .6;
  }
  70% {
      opacity: .7;
  }
  80% {
      opacity: .8;
  }
  90% {
      opacity: .9;
  }
  100% {
      opacity: 1;
  }
}
